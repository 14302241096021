import "./styles.scss";

import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

import Button from "@components/Button";

function TitleSection({ title, image, children }) {
  const { t } = useLocale();
  const content_length =
    children?.props?.dangerouslySetInnerHTML?.__html?.length;
  const [descriptionHidden, setDescriptionHidden] = useState(
    !(content_length < 450)
  );

  return (
    <div className="title-section">
      <div className="title-section__top">
        <h1>{title}</h1>
        <div className="title-section__image">
          {!!image && <GatsbyImage image={image} alt="" />}
        </div>
      </div>
      <div className="title-section__content">
        <div style={{ maxHeight: descriptionHidden ? `190px` : "100%" }}>
          {children}
        </div>
        {descriptionHidden && (
          <Button onClick={() => setDescriptionHidden(false)}>
            {t("Czytaj więcej")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default TitleSection;
