import React, { useState } from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Breadcrumbs from "@components/Breadcrumbs";
import TitleSection from "@components/TitleSection";
import Gallery from "@components/Gallery";
import Button from "@components/Button";
import ReservationTiles from "@components/ReservationTiles";
import ActualPromotions from "@components/ActualPromotions";
import Seo from "@components/Seo";

function SubofferPage({ data, pageContext }) {
  const { seo } = data?.seo_data?.nodes[0];
  let { gallery_thumbnails, gallery_fullImage } = data;
  gallery_thumbnails =
    gallery_thumbnails.nodes[0]?.acfSuboffer?.subofferGallery;
  gallery_fullImage = gallery_fullImage.nodes[0]?.acfSuboffer?.subofferGallery;
  const reservation_items =
    data?.reservation_data?.nodes[0]?.pageHome?.reservation;

  const [numberPhotos, setNumberPhotos] = useState(6);

  const { title, description, image, priceList } = pageContext;
  const gatsby_featured_image = getImage(image?.gatsbyImage);

  const breadcrumbs_data = [
    { name: pageContext.parrentTitle, href: `/${pageContext?.parrentSlug}` },
    { name: title },
  ];

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="offer-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <TitleSection title={title} image={gatsby_featured_image} s>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </TitleSection>
        </div>
        {gallery_thumbnails?.length > 0 && (
          <section className="offer-page__gallery">
            <div className="container">
              <h2>Galeria</h2>
              <Gallery
                limit={numberPhotos}
                data={{
                  thumbnails: gallery_thumbnails,
                  fullImages: gallery_fullImage,
                }}
              />
              {gallery_thumbnails?.length > numberPhotos && (
                <Button
                  variant="white"
                  onClick={() => setNumberPhotos((prev) => prev + 6)}
                >
                  Pokaż więcej zdjęć
                </Button>
              )}
            </div>
          </section>
        )}

        {priceList && (
          <section className="offer-page__price-list">
            <div className="container">
              <h2>Cennik</h2>
              <table>
                <tr>
                  <th>Opis zabiegu</th>
                  <th>Kwota</th>
                  <th>Uwagi</th>
                </tr>
                {priceList.map((item) => (
                  <tr>
                    <td>{item.procedureDescription}</td>
                    <td>{item.procedurePrice} zł</td>
                    <td>{item.procedureRemarks || "-"}</td>
                  </tr>
                ))}
              </table>
            </div>
          </section>
        )}

        <section className="offer-page__reservation">
          <div className="container">
            <h2>Rezerwacja wizyty</h2>
          </div>
          <ReservationTiles items={reservation_items} />
        </section>

        <section className="offer-page__promotions">
          <ActualPromotions />
        </section>
      </div>
    </>
  );
}

export default SubofferPage;

export const query = graphql`
  query ($title: String) {
    gallery_thumbnails: allWpOferta(
      filter: { title: { eq: $title } }
      limit: 6
    ) {
      nodes {
        acfSuboffer {
          subofferGallery {
            sourceUrl
          }
        }
      }
    }
    gallery_fullImage: allWpOferta(
      filter: { title: { eq: $title } }
      limit: 6
    ) {
      nodes {
        acfSuboffer {
          subofferGallery {
            sourceUrl
          }
        }
      }
    }
    reservation_data: allWpPage(filter: { title: { eq: "Strona główna" } }) {
      nodes {
        pageHome {
          reservation {
            title
            description
            link
            image {
              gatsbyImage(width: 546)
            }
          }
        }
      }
    }
    seo_data: allWpOferta(filter: { title: { eq: $title } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
